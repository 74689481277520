<template>
  <main class="main">
    <promo-section
      class="promo--investors"
      :subtitle="promo.subtitle"
      :title="promo.title"
      :image="promo.image"
    />
    <investors-section />
  </main>
</template>

<script>
import PromoSection from '@/components/PromoSection.vue';
import InvestorsSection from '@/components/investors-page/InvestorsSection.vue';
import promoImage from '@/assets/images/investors-promo.svg';

export default {
  name: 'Investors',
  components: {
    PromoSection,
    InvestorsSection,
  },
  data() {
    return {
      promo: {
        subtitle: 'Investors',
        title: 'Get behind <span class="text-accent">the latest and greatest</span> minds in insurance.',
        image: promoImage,
      },
    };
  },
};
</script>
