<template>
  <div
    class="investors"
    id="investors"
  >
   <div class="investors__container container">
     <h2 v-if="title" class="investors__title" v-html="title" />
     <div class="investors__row">
        <div
          v-for="investor in investors"
          :key="investor.id"
          class="investors__item investor"
        >
          <div class="investor__logo">
            <img :src="investor.logo" alt="">
          </div>
          <p class="investor__description" v-text="investor.description" />
        </div>
      </div>
   </div>
  </div>
</template>

<script>
import khosta from '@/assets/images/investor-khosla.png';
import bezos from '@/assets/images/investor-bezos.png';
import marc from '@/assets/images/investor-marc.png';
import goldman from '@/assets/images/investors-goldman.png';

export default {
  name: 'InvestorsSection',
  data() {
    return {
      investors: [
        {
          id: 1,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. .  minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. .',
          logo: khosta,
        },
        {
          id: 2,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. .  minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. .',
          logo: bezos,
        },
        {
          id: 3,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. .  minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. .',
          logo: marc,
        },
        {
          id: 4,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. .  minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt ut labore et dolore magna aliqua. .',
          logo: goldman,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.investors {
  padding: 100px 0 70px;

  @include breakpoint(0, $lg) {
    padding: 70px 0 35px;
  }

  @include breakpoint(0, $sm) {
    padding: 35px 0 25px;
  }

  &__row {
    max-width: 980px;
    margin: 0 auto;
  }

  &__item {
    margin-bottom: 40px;

    @include breakpoint(0, $lg) {
      margin-bottom: 60px;
    }

    @include breakpoint(0, $md) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

}

.investor {
  display: flex;
  align-items: center;

  @include breakpoint(0, $lg) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__logo {
    width: 400px;
    max-width: 100%;
    flex: none;
    text-align: center;

    @include breakpoint(0, $lg) {
      margin-bottom: 20px;
      width: 100%;
      text-align: left;
    }
  }

  &__description {
    font-family: $secondary-font-family;
    font-size: 16px;
    line-height: 1.4;

    @include breakpoint(0, $sm) {
      font-size: 14px;
    }
  }

}

</style>
